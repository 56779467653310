<template>
  <div class="fix">
    <div class="back">
      <div
        style="
          display: flex;
          justify-content: space-between;
          box-sizing: border-box;
          padding: 30px 0 0;
          margin: 0 auto;
          width: 1200px;
        "
      >
        <!-- <div class="logo"></div> -->
        <img style="height: 120px" :src="logo" alt="" />
        <div class="serve" >
          <div class="service">服务说明</div>
          <div class="agreement" style="margin-top: 22px" @click="about(0)">
            常见问题
          </div>
          <div class="agreement" style="margin-top: 22px" @click="about(1)">
            关于我们
          </div>
          <div
            class="agreement"
            style="margin-top: 22px"
            @click="toagreement(3)"
          >
            用户服务协议
          </div>
          <div
            class="agreement"
            style="margin-top: 14px"
            @click="toagreement(2)"
          >
            隐私协议
          </div>
        </div>
        <div class="serve" >
          <div class="service">我的账户</div>
          <div class="agreement" style="margin-top: 22px" @click="toorder(1)">
            我的订单
          </div>
          <div class="agreement" style="margin-top: 14px" @click="toorder(0)">
            我的体验卡
          </div>
        </div>
        <div class="line1"></div>
        <div>
          <div class="phone">{{ phone }}</div>
          <div style="display: flex; margin-top: 16px">
            <span class="time">工作时间：</span>
            <span class="time" style="width: 155px; display: block">{{
              worktime
            }}</span>
          </div>
          <div style="margin-top: 16px">
            <span class="time">客服邮箱：</span>
            <span class="time">{{ emel }}</span>
          </div>
        </div>
        <div class="img" v-if="qroc">
          <img class="img" :src="qroc" alt="" />
        </div>
      </div>
      <div class="bottom time">
        {{name}} | {{ Beian }} | 京公网安备11010802022657号 @
        2024 Microsoft
      </div>
    </div>
  </div>
</template>

<script>
import { getBeian, getBottom, getName } from "@/common/service";
export default {
  data() {
    return {
      Beian: "",
      logo: "",
      phone: "",
      emel: "",
      worktime: "",
      qroc: "",
      name:'',
    };
  },
  created() {
    this.getBeian();
    this.getBottom();
    this.getName()
  },
  methods: {
    getName(){
      getName({}).then((res)=>{
        this.name = res.data[0].value
      })
    },
    getBottom() {
      getBottom({}).then((res) => {
        this.logo = res.data[0].value;
        this.phone = res.data[1].value;
        this.emel = res.data[2].value;
        this.worktime = res.data[3].value;
        this.qroc = res.data[4].value;
      });
    },
    getBeian() {
      getBeian({}).then((res) => {
        this.Beian = res.data[0].value;
      });
    },
    toorder(index) {
      if (localStorage.getItem("token")) {
        this.$router.push({
          path: "myorder",
          query: {
            index: index,
          },
        });
      } else {
        this.$message.error("请登录后操作");
      }
    },
    toagreement(num) {
      localStorage.setItem("num", num);
      this.$router.push({
        path: "/agreement",
        // query: {
        //   num: num,
        // },
      });
    },
    about(num) {
      this.$router.push({
        path: "/about",
        query: {
          num: num,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.bottom {
  border-top: 1px solid rgba(151, 151, 151, 0.5);
  height: 53px;
  text-align: center;
  font-size: 12px;
  font-family: SFPro, SFPro;
  font-weight: 400;
  color: #ffffff;
  margin-top: 50px;
  line-height: 53px;
}

.time {
  font-size: 12px;
  font-family: SFPro, SFPro;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.7;
}

.img {
  width: 132px;
  height: 132px;
  // background: #ffffff;
  // margin-left: 90px;
}

.phone {
  font-size: 32px;
  font-family: Arial, Arial;
  font-weight: normal;
  color: #ffffff;
}

.line1 {
  width: 1px;
  height: 132px;
  background: rgba(151, 151, 151, 0.4);
  // margin-left: 128px;
  // margin-right: 106px;
}

.service {
  font-size: 16px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}

.agreement {
  font-size: 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
}

.back {
  width: 100%;
  height: 300px;
  background: #333333;
  border: 1px solid #979797;
  box-sizing: border-box;
}

.logo {
  width: 148px;
  height: 46px;
  // background: #d8d8d8;
}

.fix {
  position: relative;
  z-index: 10;
  width: 100%;
  min-height: 300px;
}

.serve {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>