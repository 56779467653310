<template>
  <div class="page4s">
    <div class="search" v-if="keywords" style="width: 1200px; margin: 0 auto">
      <span>共{{ total }}条"</span>
      <span style="color: rgba(6, 169, 113, 1);">{{ keywords }}</span>
      <span>"相关的搜素结果</span>
    </div>
    <div class="videobox">
      <div class="item9" v-for="(item, index) in videoList" :key="index" @click="tovideoinfo(item.id)">
        <img :src="item.image" style="width: 225px; height: 136px" alt="" />
        <div style="padding: 10px 0 0 10px">
          <div class="title">{{ item.title }}</div>
          <div class="writer">{{ item.lecturer }}</div>
          <div class="money">¥{{ item.price }}</div>
        </div>
      </div>
    </div>
    <div class="block" v-if="videoList.length>0">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="currentPage3" :page-size="10" layout="prev, pager, next, jumper" :total="total" background>
      </el-pagination>
    </div>
    <!-- <u-popup v-model="show1" mode="center"> -->
    <!-- <div v-if="show1" class="" style="font-size: 0;">
      <video :src='url' class="video" id="demoVideo" :controls="true" :enable-progress-gesture="false">
      </video>
    </div> -->
    <!-- </u-popup> -->
  </div>
</template>

<script>
import { getVideoList } from "@/common/service";
export default {
  data() {
    return {
      page: 1,
      lime: 10,
      keywords: "",
      currentPage3: 1,
      videoList: [],
      total: 0,
      show1: false
    };
  },
  created() {
    this.getVideoList();
  },
  methods: {
    search(keyword) {
      console.log('keyword', keyword);
      this.keywords = keyword
      this.getVideoList()
    },
    getVideoList() {
      getVideoList({
        page: this.page,
        lime: this.limit,
        keywords: this.keywords,
      }).then((res) => {
        this.videoList = res.data.data;
        this.total = res.data.total;
      });
    },
    tovideoinfo(id) {
      this.$router.push({
        path: "/videoinfo",
        query: {
          id: id
        }
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.page = val;
      console.log(`当前页: ${val}`);
      this.getVideoList();
    },
  },
};
</script>

<style>
.search {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #333333;
  /* padding: 24px 0 0 0; */
}
.block {
  display: flex;
  justify-content: center;
  margin-top: 56px;
}

.money {
  height: 24px;
  font-size: 18px;
  font-family: MicrosoftYaHei;
  color: #cc3300;
  margin-top: 33px;
}

.title {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #333333;
}

.writer {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #333333;
  margin-top: 14px;
}

.videobox {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.item9 {
  width: 225px;
  height: 266px;
  background: #ffffff;
}

.page4s {
  background: rgba(255, 255, 255, 1);
  padding-top: 24px;
}
</style>