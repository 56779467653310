import axios from "axios";
export let baseURL = "https://www.bsup.com.cn";


const instance = axios.create({
  baseURL,
});

instance.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("token")
    if (token) {
      config.headers.token = token
    }
    return config;
  },
  (error) => {
    throw Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    switch (res.status) {
      case 201:
      case 200:
        return res.data
      default:
        throw Promise.reject(res.data);
    }
  },
  (error) => {
  }
);

export const request = {
  get: (url, params, config) => instance.get(url, { params, ...config }),
  post: (url, params, config) => instance.post(url, params, config),
};

