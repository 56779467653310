import { request } from "./request";
//注册
export const batchDelSwitch = (data) => request.post("api/user/register", data)
//密码登陆
export const userlogin = (data) => request.post("api/user/login", data)
//发送验证码
export const send = (data) => request.post("/api/sms/send", data)
//验证码登陆
export const mobilelogin = (data) => request.post("/api/user/mobilelogin", data)
//检验验证码
export const check = (data) => request.post("/api/sms/check", data)
//获取轮播图
export const getBanner = (data) => request.post("/api/banner/getBanner", data)
//教材列表
export const getBooksList = (data) => request.post("api/books/getBooksList", data)
//教材详情
export const getBookInfo = (data) => request.post("api/books/getBookInfo", data)
// 视频列表
export const getVideoList = (data) => request.post("api/video/getVideoList", data)
//视频详情
export const getVideoInfo = (data) => request.post("api/video/getVideoInfo", data)
//体验卡列表
export const getMyCertificate = (data) => request.post("/api/certificate/getMyCertificate", data)
//使用体验卡
export const useCertificate = (data) => request.post("/api/certificate/useCertificate", data)
//用户详情
export const getUserinfo = (data) => request.post("api/user/getUserinfo", data)
//获取教材级别
export const getBooksLevel = (data) => request.post("api/books/getBooksLevel", data)
//教学层次
export const getBooksArrangement = (data) => request.post("api/books/getBooksArrangement", data)
//专业类型
export const getBooksSpecialityt = (data) => request.post("api/books/getBooksSpeciality", data)
//获取相关系列
export const getSeriesBooks = (data) => request.post("api/books/getSeriesBooks", data)
//投稿
export const addContribute = (data) => request.post("api/books/addContribute", data)
//样书申请
export const addSampleApply = (data) => request.post("api/books/addSampleApply", data)
//创建订单
export const createOrder = (data) => request.post("api/order/createOrder", data)
//订单列表
export const getOrderList = (data) => request.post("api/order/getOrderList", data)
//订单详情
export const getOrderInfo = (data) => request.post("api/order/getOrderInfo", data)
//删除订单
export const delOrder = (data) => request.post("api/order/delOrder", data)
//取消订单
export const cancellationOrder = (data) => request.post("api/order/cancellationOrder", data)
//微信支付
export const wxpay = (data) => request.post("api/pay/wxpay", data)
//支付宝支付
export const alipay = (data) => request.post("api/pay/alipay", data)
//文件上传
export const upload = (data) => request.post("/api/common/upload", data)
//关于我们
export const getHelpConfig = (data) => request.post("/api/common/getHelpConfig", data)
//订单支付
export const orderPay = (data) => request.post("api/order/orderPay", data)
//重置密码
export const resetpwd = (data) => request.post("api/user/resetpwd", data)
//备案信息
export const getBeian = (data) => request.post("/api/common/getBeian", data)
//获取基本信息
export const getBottom = (data) => request.post("/api/common/getBottomText", data)
//获取网站名称
export const getName = (data) => request.post("/api/common/getName", data)





