import { render, staticRenderFns } from "./BookX.vue?vue&type=template&id=c980b910&scoped=true"
import script from "./BookX.vue?vue&type=script&lang=js"
export * from "./BookX.vue?vue&type=script&lang=js"
import style0 from "./BookX.vue?vue&type=style&index=0&id=c980b910&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c980b910",
  null
  
)

export default component.exports