<template>
  <div>
    <div
      class="pageindex"
      :style="{ background: tab == 0 ? 'rgb(244, 244, 244)' : '#fff' }"
    >
      <div class="header1">
        <div style="margin: 0 auto; width: 1200px">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <div class="top">
              <!-- <div style="height: 40px;"> -->
                <img style="height: 62px;" :src="logo" alt="">
              <!-- </div> -->
              <div class="u-flex">
                <input
                  placeholder="请输入关键词搜索"
                  type="text"
                  class="ipt"
                  v-model="keyword"
                  @keyup.enter="search"
                />
                <!-- <div @click="search">搜索</div> -->
              </div>
            </div>
            <div class="fix">
              <div
                v-if="!login"
                class="login"
                style="cursor: pointer"
                @click="tologin"
              >
                登录
              </div>
              <div
                v-if="!login"
                class="register"
                style="cursor: pointer"
                @click="tohome"
              >
                免费注册
              </div>
              <el-dropdown @command="myorder">
                <div style="position: relative" v-if="login" class="zhang">
                  {{ nickname }}
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    :command="index"
                    v-for="(item, index) in list"
                    :key="index"
                    >{{ item.name }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <div
            style="
              margin-top: 6px;
              display: flex;
              justify-content: space-between;
            "
          >
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane
                v-show="isSearch"
                label="首页"
                name="first"
              ></el-tab-pane>
              <el-tab-pane label="纸质书" name="second"></el-tab-pane>
              <el-tab-pane label="电子书" name="third"></el-tab-pane>
              <el-tab-pane label="名师教学视频" name="fourth"></el-tab-pane>
            </el-tabs>
            <div class="rightt">
              <img
                src="../assets/images/issue.png"
                style="width: 12px; height: 12px"
                alt=""
              />
              <span style="margin-left: 5px; cursor: pointer" @click="tou"
                >投稿</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- <HeaderIndex></HeaderIndex> -->
      <div
        style="background: rgba(244, 244, 244, 1); padding: 26px 0 46px"
        v-if="tab == 0"
      >
        <!-- 轮播图 -->
        <div class="swiper" v-if="bannerList.length>0">
          <div class="">
            <el-carousel height="400px">
              <el-carousel-item
                v-for="(item, index) in bannerList"
                :key="index"
              >
                <img
                  :src="item.image"
                  style="width: 100%; height: 400px"
                  alt=""
                  @click="toinfo(item.jump_id, item.jump_type)"
                />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <!-- 获奖 -->
        <div class="bear">
          <div class="flx">
            <span class="mu">获奖材料</span>
            <div class="more" @click="tomore('is_awards')">查看更多</div>
          </div>
          <div class="box">
            <div
              @click="toinfo(item.id)"
              class="item"
              v-for="(item, index) in BooksList"
              :key="index"
              style="text-align: left;"
            >
              <img
                :src="item.image"
                style="width: 158px; height: 204px"
                alt=""
              />
              <div>
                <span class="ding">定价</span>
                <span class="money">¥{{ item.entity_price }}</span>
              </div>
              <div class="titlea">{{ item.title }}</div>
              <div
                class="writer"
                style="
                  width: 100%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
              >
                {{ item.author }}
              </div>
            </div>
          </div>
        </div>
        <!-- 新书推荐 -->
        <div class="bear">
          <div class="flx">
            <span class="mu">新书推荐</span>
            <div class="more" @click="tomore('is_new_recommend')">查看更多</div>
          </div>
          <div class="box">
            <div
              @click="toinfo(item.id)"
              class="item"
              v-for="(item, index) in newList"
              :key="index"
            >
              <img
                :src="item.image"
                style="width: 158px; height: 204px"
                alt=""
              />
              <div>
                <span class="ding">定价</span>
                <span class="money">¥{{ item.entity_price }}</span>
              </div>
              <div class="titlea">{{ item.title }}</div>
              <div
                class="writer"
                style="
                  width: 100%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
              >
                {{ item.author }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="padding-bottom: 46px" v-show="tab == 1">
        <BookXVue ref="BookXVue" :keys="keys"></BookXVue>
      </div>
      <div style="padding-bottom: 46px" v-show="tab == 2">
        <electronicBook ref="electronicBook"></electronicBook>
      </div>
      <div style="padding-bottom: 46px" v-show="tab == 3">
        <videoV ref="videoV"></videoV>
      </div>
    </div>
    <BottomB v-if="tab == 0"></BottomB>
  </div>
</template>

<script>
// import HeaderTopVue from "../components/HeaderTop.vue";
import BottomB from "@/components/bottomB.vue";
import BookXVue from "../components/BookX.vue";
import videoV from "@/components/videoV.vue";
import electronicBook from "@/components/electronicBook.vue";
import { getBanner, getBooksList, getUserinfo,getBottom } from "@/common/service";
// import HeaderIndex from "@/components/HeaderIndex.vue";
export default {
  name: "HomeView",
  components: {
    // HeaderTopVue,
    BottomB,
    BookXVue,
    videoV,
    electronicBook,
    // HeaderIndex
  },
  created() {
    this.getBottom()
    document.title = "体育教材网";
    if (localStorage.getItem("token")) {
      this.login = true;
      this.getUserinfo();
    }
    if (this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword;
      this.$nextTick(() => {
        this.search();
      });
    }
    if (this.$route.query.tab != undefined) {
      this.tab = this.$route.query.tab;
      if (this.tab == 1) {
        this.activeName = "second";
        document.title = "纸质书";
      } else if (this.tab == 2) {
        this.activeName = "third";
        document.title = "电子书";
      } else if (this.tab == 3) {
        this.activeName = "fourth";
        document.title = "名师教学视频";
      } else {
        this.activeName = "first";
        document.title = "体育教材网";
      }
    }
  },
  mounted() {
  },
  data() {
    return {
      logo:'',
      keys: "",
      activeName: "first",
      tab: 0,
      bannerList: [],
      page: 1,
      limit: 20,
      BooksList: [],
      newList: [],
      login: false,
      nickname: "",
      isSearch: true,
      keyword: "",
      list: [
        {
          name: "体验卡",
        },
        {
          name: "我的订单",
        },
        {
          name: "设置",
        },
        {
          name: "退出",
        },
      ],
    };
  },
  methods: {
    getBottom(){
      getBottom({}).then((res)=>{
        this.logo = res.data[0].value
        // this.phone = res.data[1].value
        // this.emel = res.data[2].value
        // this.worktime = res.data[3].value
        // this.qroc = res.data[4].value
      })
    },
    //投稿
    tou() {
      if (localStorage.getItem("token")) {
        this.$router.push({
          path: "contribute",
        });
      } else {
        this.$message.error("请登录后操作");
      }
    },
    myorder(command) {
      console.log(command);
      if (command == 3) {
        //退出登录
        localStorage.removeItem("token");
        this.$router.go(0);
      } else {
        this.$router.push({
          path: "/myorder",
          query: {
            index: command,
          },
        });
      }
    },
    //搜索
    search() {
      console.log(111);
      if (this.keyword) {
        this.isSearch = false;
        if (this.tab == 0) {
          this.tab = 1;
          this.activeName = "second";
          document.title = "纸质书";
        }
        if (this.tab == 1) {
          this.$refs.BookXVue.search(this.keyword);
        }
        if (this.tab == 2) {
          this.$refs.electronicBook.search(this.keyword);
        }
        if (this.tab == 3) {
          this.$refs.videoV.search(this.keyword);
        }
      }
      console.log("e");
    },
    tomore(keys) {
      this.tab = 1;
      this.activeName = "second";
      document.title = "纸质书";
      this.$nextTick(() => {
        this.$refs["BookXVue"][keys] = 0;
        this.$refs.BookXVue.getBooksList();
      });
    },
    tohome() {
      this.$router.push({
        path: "/home",
      });
    },
    //去登陆
    tologin() {
      this.$router.push({
        path: "/codelogin",
      });
    },
    // myorder() {
    //   this.$router.push({
    //     path: "/myorder",
    //   });
    // },
    getUserinfo() {
      getUserinfo().then((res) => {
        this.nickname = res.nickname;
      });
    },
    handleClick(tab) {
      this.tab = tab.index;
      if (this.tab == 0) {
        document.title = "体育教材网";
      }
      if (this.tab == 1) {
        document.title = "纸质书";
        this.$nextTick(() => {
          this.$refs.BookXVue.is_new_recommend = null;
          this.$refs.BookXVue.is_awards = null;
          this.$refs.BookXVue.getBooksList();
        });
        if (this.keyword) {
          this.$refs.BookXVue.search(this.keyword);
        } else {
          this.keyword = "";
          this.$refs.BookXVue.search("");
        }
      }
      if (this.tab == 2) {
        document.title = "电子书";
        if (this.keyword) {
          this.$refs.electronicBook.search(this.keyword);
        } else {
          this.keyword = "";
          this.$refs.electronicBook.search("");
        }
      }
      if (this.tab == 3) {
        document.title = "名师教学视频";
        if (this.keyword) {
          this.$refs.videoV.search(this.keyword);
        } else {
          this.keyword = "";
          this.$refs.videoV.search("");
        }
      }
    },
    toinfo(id, type = 0) {
      if (type == 0) {
        this.$router.push({
          path: "/info",
          query: {
            id: id,
          },
        });
      }
      if (type == 1) {
        this.$router.push({
          path: "/videoinfo",
          query: {
            id: id,
          },
        });
      }
    },
    Banner() {
      getBanner({}).then((res) => {
        this.bannerList = res.data;
      });
      getBooksList({
        is_awards: 0,
        page: this.page,
        limit: this.limit,
      }).then((res) => {
        this.BooksList = res.data.data;
      });
      getBooksList({
        is_new_recommend: 0,
        page: this.page,
        limit: this.limit,
      }).then((res) => {
        this.newList = res.data.data;
        console.log(res);
      });
    },
  },
  mounted() {
    console.log(this.tab);
    this.Banner();
  },
};
</script>
    
<style scoped lang="scss">
.rightt {
  width: 64px;
  height: 34px;
  border-radius: 4px;
  border: 1px solid #06a971;
  font-size: 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #06a971;
  line-height: 34px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -5px;
}

.box {
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 8px;
}

.writer {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #1a66b3;
  margin-top: 10px;
}

.ding {
  font-size: 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.money {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #cc3300;
}

.titlea {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
}

.item {
  width: 178px;
  height: 314px;
  border: 1px solid rgba(151, 151, 151, 0.2);
  margin-top: 16px;
  padding: 10px;
  box-sizing: border-box;
  margin-right: 13px;
  cursor: pointer;
}

.flx {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mu {
  height: 29px;
  font-size: 22px;
  font-family: MicrosoftYaHei;
  color: #333333;
}

.more {
  width: 84px;
  height: 30px;
  border-radius: 2px;
  background: rgba(6, 169, 113, 0.1);
  font-size: 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #06a971;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}

.bear {
  width: 1200px;
  // height: 766px;
  background: #ffffff;
  border-radius: 6px;
  padding: 31px 26px;
  margin: 18px auto 0;
  box-sizing: border-box;
}

.swiper {
  height: 400px;
  width: 1200px;
  margin: 0 auto 0;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.top {
  display: flex;
  align-items: center;
}

.ipt {
  width: 700px;
  height: 36px;
  background: #f4f4f4;
  border-radius: 6px;
  border: 1px solid #d8d8d8;
  padding: 0 20px;
  margin-left: 58px;
}

.fix {
  display: flex;
  align-items: center;
}

.login {
  width: 64px;
  height: 36px;
  background: rgba(6, 169, 113, 0.4);
  border-radius: 4px;
  font-size: 13px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #06a971;
  line-height: 36px;
  text-align: center;
}

.register {
  width: 84px;
  height: 36px;
  background: #06a971;
  border-radius: 4px;
  font-size: 13px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 36px;
  text-align: center;
  margin-left: 12px;
}

.logo {
  width: 192px;
  height: 40px;
  // background: #d8d8d8;
}

.header1 {
  /* width: 1920px; */
  height: 118px;
  background: #ffffff;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
  padding: 10px 0 0;
  box-sizing: border-box;
  overflow: hidden;
  border: 0px solid #000;
}
.zhang {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #06a971;
}

.pageindex {
  width: 100%;
  // min-height: 100vh;
}
::v-deep .el-tabs__item {
  font-weight: 700;
}
</style>