<template>
  <div>
    <div class="search" v-if="keywords" style="width: 1200px; margin: 0 auto">
      <span>共{{ total }}条"</span>
      <span style="color: rgba(6, 169, 113, 1)">{{ keywords }}</span>
      <span>"相关的搜素结果</span>
    </div>
    <div class="top1" v-if="keywords == ''">
      <div class="flx mar">
        <div class="left">按教材级别：</div>
        <div class="right flx">
          <div
            :class="level_id == null ? 'all' : 'item'"
            style="margin-right: 25px"
            @click="level()"
          >
            全部
          </div>
          <div
            :class="level_id == item.id ? 'all' : 'item'"
            v-for="(item, index) in booklevel"
            @click="level(item.id)"
            :key="index"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
      <div class="flx mar">
        <div class="left">按教学层次：</div>
        <div class="right flx">
          <div
            :class="arrangement_id == null ? 'all' : 'item'"
            style="margin-right: 25px"
            @click="arrangement()"
          >
            全部
          </div>
          <div
            :class="arrangement_id == item.id ? 'all' : 'item'"
            v-for="(item, index) in Arrangement"
            :key="index"
            @click="arrangement(item.id)"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
      <div class="flx mar">
        <div class="left">按专业类型：</div>
        <div class="right flx">
          <div
            :class="speciality_id == null ? 'all' : 'item'"
            style="margin-right: 25px"
            @click="speciality()"
          >
            全部
          </div>
          <div
            @click="speciality(item.id)"
            :class="speciality_id == item.id ? 'all' : 'item'"
            v-for="(item, index) in Specialityt"
            :key="index"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
      <div class="flx mar">
        <div class="flx">
          <div class="left">按获奖教材：</div>
          <div class="right flx">
            <div
              :class="is_awards == null ? 'all' : 'item'"
              style="margin-right: 25px"
              @click="awards()"
            >
              不限
            </div>
            <div
              :class="is_awards == index ? 'all' : 'item'"
              v-for="(item, index) in list"
              :key="index"
              @click="awards(index)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="flx">
          <div class="left">按新书推荐：</div>
          <div class="right flx">
            <div
              :class="is_new_recommend == null ? 'all' : 'item'"
              style="margin-right: 25px"
              @click="recommend()"
            >
              不限
            </div>
            <div
              :class="is_new_recommend == index ? 'all' : 'item'"
              v-for="(item, index) in list"
              :key="index"
              @click="recommend(index)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box2">
      <div
        @click="toinfo(item.id)"
        style="margin-right: 15px"
        class="book1"
        v-for="(item, index) in booklist"
        :key="index"
      >
        <img :src="item.image" style="width: 205px; height: 270px" alt="" />
        <div class="titlet">{{ item.title }}</div>
        <div class="wri">作者：{{ item.author }}</div>
        <div>
          <span class="wri">定价：</span>
          <span class="wri" style="color: rgba(204, 51, 0, 1)"
            >¥{{ item.entity_price }}</span
          >
        </div>
      </div>
    </div>
    <div class="block" v-if="booklist.length > 0">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage3"
        :page-size="10"
        layout="prev, pager, next, jumper"
        :total="total"
        background
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  getBooksList,
  getBooksLevel,
  getBooksArrangement,
  getBooksSpecialityt,
} from "@/common/service";
export default {
  data() {
    return {
      list: [
        {
          name: "是",
        },
        {
          name: "否",
        },
      ],
      currentPage3: 1,
      page: 1,
      limit: 10,
      booklist: [],
      total: "",
      booklevel: [], //教材级别
      Arrangement: [], //教学层次
      Specialityt: [], //专业类型
      level_id: null, // 教材级别id
      arrangement_id: null, //教学层次id
      speciality_id: null, //专业类型id
      is_new_recommend: null,
      is_awards: null,
      keywords: "",
    };
  },
  created() {
    this.getBooksList();
    this.getBooksLevel();
    this.getBooksArrangement();
    this.getBooksSpecialityt();
  },
  methods: {
    recommend(index) {
      this.is_new_recommend = index;
      this.getBooksList();
    },
    awards(index) {
      this.is_awards = index;
      this.getBooksList();
    },
    arrangement(id) {
      this.arrangement_id = id;
      this.getBooksList();
    },
    speciality(id) {
      this.speciality_id = id;
      this.getBooksList();
    },
    level(index) {
      this.level_id = index;
      this.getBooksList();
    },
    //专业类型
    getBooksSpecialityt() {
      getBooksSpecialityt().then((res) => {
        this.Specialityt = res.data;
      });
    },
    //获取教学层次
    getBooksArrangement() {
      getBooksArrangement().then((res) => {
        console.log(res);
        this.Arrangement = res.data;
      });
    },
    //获取教材级别
    getBooksLevel() {
      getBooksLevel().then((res) => {
        console.log(res);
        this.booklevel = res.data;
      });
    },
    search(keyword) {
      console.log("keyword", keyword);
      this.keywords = keyword;
      this.getBooksList();
    },
    toinfo(id) {
      this.$router.push({
        path: "/info",
        query: {
          id: id,
        },
      });
    },
    getBooksList() {
      getBooksList({
        level_id: this.level_id,
        arrangement_id: this.arrangement_id,
        speciality_id: this.speciality_id,
        book_type: 0,
        is_new_recommend: this.is_new_recommend,
        is_awards: this.is_awards,
        page: this.page,
        limit: this.limit,
        keywords: this.keywords,
      }).then((res) => {
        console.log(res);
        this.booklist = res.data.data,
        this.total = res.data.total;
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.page = val;
      console.log(`当前页: ${val}`);
      this.getBooksList();
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #333333;
  padding: 24px 0 0 0;
}
.block {
  display: flex;
  justify-content: center;
  margin-top: 56px;
}
.wri {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #333333;
  margin-top: 10px;
}
.titlet {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #333333;
  margin-top: 10px;
  height: 38px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.book1 {
  width: 225px;
  min-height: 398px;
  border: 1px solid rgba(151, 151, 151, 0.2);
  padding: 10px;
  box-sizing: border-box;
  margin-top: 16px;
}
.all {
  height: 32px;
  color: #06a971;
  border-radius: 4px;
  line-height: 32px;
  background: rgba(6, 169, 113, 0.1);
  padding: 0px 11px;
  box-sizing: border-box;
  font-size: 14px;
  margin-right: 10px;
}
.mar {
  margin-top: 37px;
}
.flx {
  display: flex;
  align-items: center;
}
.item {
  height: 32px;
  border-radius: 4px;
  line-height: 32px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #777777;
  margin-right: 10px;
  padding: 0px 11px;
  box-sizing: border-box;
}
.box2 {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}
.top1 {
  width: 1200px;
  height: 252px;
  margin: 0 auto;
  /* padding: 30px 0 35px; */
}
.left {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #333333;
  font-weight: 700;
}
</style>